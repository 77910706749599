<template>
  <div>
    <div class="field__header pa-0">
      Select delay type
    </div>
    <v-radio-group
      :value="configuration.type"
      row
      class="custom-field-radio-group mb-7"
      @change="onTypeChange($event)"
    >
      <v-radio value="standard" label="Standard" />
      <v-radio value="specific" label="Specific" />
    </v-radio-group>

    <div v-if="configuration.type === 'specific'">
      <div class="notification-menu__header">
        What date & time should the contact be delayed until?
      </div>

      <CustomDateAndTimePicker
        :datetime="configuration.specificDateTime"
        placeholder="Pick date and time"
        :allowed_minutes="[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]"
        @date-input="
          date = $event;
          updateDatetime()
        "
        @time-input="
          time = $event;
          updateDatetime()
        "
      />

      <div class="notification-menu__header">
        What is the time zone for the date & time above?
      </div>
      <CustomDropdown
        :value="configuration.specificTimezone"
        :items="timeZones"
        class="mb-3"
        item-text="text"
        item-value="value"
        @change="handleTimezoneChange"
      />
    </div>

    <hr v-if="configuration.type === 'specific'" class="mb-5">

    <div class="notification-menu__header">
      How long should the contact be delayed?
    </div>

    <v-row class="pt-0 mb-3">
      <v-col cols="2" class="py-0">
        <CustomTextInput
          :value="configuration.numberOfUnits"
          type="number"
          @input="
            $emit('update:configuration', {
              ...configuration,
              numberOfUnits: $event,
            });
          "
        />
      </v-col>
      <v-col cols="4" class="py-0">
        <CustomDropdown
          :value="configuration.unitOfTime"
          :items="delayUnits"
          @input="
            $emit('update:configuration', {
              ...configuration,
              unitOfTime: $event,
            })
          "
        />
      </v-col>
    </v-row>

    <div
      v-if="configuration.type === 'specific'"
      class="comment-text"
    >
      The contact will be delayed by this much time if they reach this
      action after the date specified above.
    </div>
  </div>
</template>

<script>
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import CustomDateAndTimePicker from "@/sharedComponents/CustomDateAndTimePicker";
import datesMixin from "@/utils/datesMixin.js";

export default {
  name: "ActionAddDelayConfiguration",
  components: {
    CustomDropdown,
    CustomTextInput,
    CustomDateAndTimePicker,
  },
  mixins: [datesMixin],
  props: {
    configuration: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      type: 0,
      date: null,
      time: null,
      delayUnits: [
        { value: "minute", label: "Minute(s)" },
        { value: "hour", label: "Hour(s)" },
        { value: "day", label: "Day(s)" },
        { value: "week", label: "Week(s)" },
      ],
      userTimezone: null,
      timeZones: [
        { value: 'America/New_York', text: 'Eastern Standard Time', short: 'EST' },
        { value: 'America/Chicago', text: 'Central Standard Time', short: 'CST' },
        { value: 'America/Denver', text: 'Mountain Time', short: 'MST' },
        { value: 'America/Los_Angeles', text: 'Pacific Time', short: 'PST' },
        { value: 'America/Anchorage', text: 'Alaska Time', short: 'AKST' },
        { value: 'America/Adak', text: 'Hawaii-Aleutian Time', short: 'HST' },
      ],
    };
  },
  watch: {
    'configuration.numberOfUnits': {
      handler() {
        this.updateConfigurationNameOnChange();
      },
    },
    'configuration.unitOfTime': {
      handler() {
        this.updateConfigurationNameOnChange();
      },
    },
    'configuration.specificDateTime': {
      handler() {
        this.updateConfigurationNameOnChange();
      },
    },
    'configuration.type': {
      handler() {
        this.updateConfigurationNameOnChange();
      },
    },
  },
  mounted() {
    if (this.configuration.specificDateTime) {
      this.date = this.configuration.specificDateTime.substring(0,14);
    }
    if (!this.configuration.specificTimezone) {
      let userOffset = (new Date()).toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];
      userOffset = userOffset.replace('D', 'S');
      const bestMatchForTimezone = this.timeZones.find(
        (x) => x.short === userOffset
      );

      if (bestMatchForTimezone) {
        this.userTimezone = bestMatchForTimezone.value;
        this.$emit("update:configuration", {
          ...this.configuration,
          specificTimezone: bestMatchForTimezone.value,
        });
      }
    }
  },
  methods: {
    updateDatetime() {
      if (this.date) {
        let date = this.date.substring(0,10);

        if (this.time) {
          date += " " + this.time;
        } else {
          date += " 00:00";
        }

        this.$emit("update:configuration", {
          ...this.configuration,
          specificDateTime: date,
          type: 'specific',
        });

        this.$nextTick(() => {
          this.handleTimezoneChange();
        })
      }
    },
    updateConfigurationNameOnChange() {
      const type = this.configuration.type;
      const specificDateTime = this.configuration.specificDateTime;
      const numberOfUnits = this.configuration.numberOfUnits;
      const unitOfTime = this.configuration.unitOfTime;

      let label = "Delay ";

      if (type === "specific" && specificDateTime) {
        label = this.getDateWithTime(specificDateTime)
      } else {
        label += "for " + (numberOfUnits|| 0) + " ";
        label += this.delayUnits.find(
          (x) => x.value === unitOfTime
        ).label;
      }
      
      return this.updateConfigurationName(label)
    },
    updateConfigurationName(name) {
      this.$emit("update:configuration", {
        ...this.configuration,
        name,
      });
    },
    onTypeChange(value) {
      if (value === 'standard') {
        this.$emit("update:configuration", {
          ...this.configuration,
          type: 'standard',
          numberOfUnits: 1,
          unitOfTime: "d",
        });
      } else {
        this.$emit("update:configuration", {
          ...this.configuration,
          type: 'specific',
          specificDateTime: null,
        });
      }
    },
    handleTimezoneChange(event) {
      this.$emit('update:configuration', {
        ...this.configuration,
        specificTimezone: event || this.configuration.specificTimezone,
      })
    }
  },
};
</script>

<style lang="scss" scoped>
:focus,
:active,
::-moz-focus-inner {
  outline: none !important;
}

.form-group:last-child {
  margin-bottom: 0;
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

::v-deep .nav-tabs .nav-item {
  :focus,
  :active,
  ::-moz-focus-inner {
    outline: none !important;
  }

  .nav-link {
    border: none;

    &:focus,
    &:active,
    &::-moz-focus-inner {
      outline: none !important;
    }

    &:after {
      border-bottom: solid 2px #ddd;
      content: "";
      display: block;
      margin-top: 0.5em;
      transform: scaleX(0);
      transition: transform 250ms ease-in-out;
    }

    &:hover:after {
      transform: scaleX(1);
    }

    &.active:after {
      transform: scaleX(1);
      border-bottom: 3px solid #094073;
    }
  }
}

::v-deep .tab-content {
  border: 0;
}
</style>
